import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Provider,
  Room,
  Disconnected,
  Connected,
  Connecting,
  Failed,
  RequestUserMedia,
  RemoteAudioPlayer,
} from '@andyet/simplewebrtc'
import { values } from 'lodash'
import { db } from '../../dataflow/firebase'

// Redux
import {
  updateRoomStatus,
  sendNotificationThunk,
} from '../../dataflow/thunks/dashboard-thunks'

// Components
import VideoCall from './VideoCall'
import ChatContainer from './chat'
import Disconnect from '../../components/diconnect-modal'
import PlaceholderChat from '../../components/placeholder-chat'

const mapDispatchToProps = (dispatch) => ({
  updateRoomStatus: (status) => {
    dispatch(updateRoomStatus(status))
  },
})

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    selectedTicket: state.dashboard.selectedTicket,
    peers: state.simplewebrtc.peers,
  }
}

// Style
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: calc(100% - 28rem);
  height: 100%;
  background: #f6f9fc;
  margin-top: 0.875rem;
`

const configUrl = `https://api.simplewebrtc.com/config/guest/2e3ea92f92b90f5ec787d37f`

class MyRoom extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isVideo: false,
      doctor: undefined,
      lostPacient: false,
    }
  }

  async componentDidMount() {
    const roomId = this.props.selectedTicket.id
    await this.sendNotification(roomId)

    this.setState({
      isVideo: this.props.isVideo || false,
      doctor: this.props.doctor,
    })

    this.updateRoomStatus(roomId)

    db.collection(process.env.REACT_APP_FIREBASE_COLLECTION_ID)
      .doc('chats')
      .collection('history')
      .doc(roomId)
      .update({
        doctorName: this.props.doctorName,
        displayName: this.props.pacient.pacient,
      })
  }

  componentDidUpdate(oldProps) {
    if (values(oldProps.peers).length > values(this.props.peers).length) {
      if (Object.keys(this.props.peers).length === 0) {
        this.setState({
          lostPacient: true,
        })
      }
    }
    if (
      values(oldProps.peers).length < values(this.props.peers).length &&
      this.state.lostPacient
    ) {
      this.setState({
        lostPacient: false,
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    // this.props.history.push({
    // 	pathname: `/room/${this.props.pacient.id}`,
    // 	state: {
    // 		inRoom: false,
    // 		pacient: {},
    // 		isVideo: false,
    // 		doctor: this.props.doctor,
    // 		doctorName: this.props.doctorName
    // 	}
    // });
  }

  sendNotification = async (roomId) => {
    await sendNotificationThunk(roomId)
  }

  updateRoomStatus = (roomId) => {
    this.props.updateRoomStatus('Em atendimento')
  }

  toggleVideo = () =>
    this.setState({
      isVideo: !this.state.isVideo,
    })

  render() {
    const roomId = this.props.pacient.id
    const { isVideo } = this.state
    const displayName = this.props.auth.userdata.name

    return (
      <Container>
        <Provider configUrl={configUrl} displayName={displayName} userData={{}}>
          <Connecting>
            <PlaceholderChat />
          </Connecting>
          <Disconnected>
            {/* Connection lost */}
            <Disconnect type='doctor' />
          </Disconnected>
          <Failed>Service Unavailable</Failed>
          <Connected>
            <Room name={roomId}>
              {({ room, sendRtt, peers }) => {
                if (!room.joined) {
                  return (
                    <>
                      <PlaceholderChat />
                    </>
                  )
                }
                if (isVideo) {
                  return (
                    <>
                      <RequestUserMedia audio video auto />
                      <RemoteAudioPlayer />
                      <VideoCall
                        {...this.props}
                        roomAddress={room.address}
                        roomId={roomId}
                        toggleVideo={this.toggleVideo}
                      />
                      <ChatContainer
                        {...this.props}
                        toggleVideo={this.toggleVideo}
                        sendRtt={sendRtt}
                        roomAddress={room.address}
                        displayName={displayName}
                      />
                      {this.state.lostPacient && (
                        <Disconnect
                          type='pacient'
                          roomId={roomId}
                          history={this.props.history}
                          pacient={this.props.pacient}
                          handleCloseChat={this.props.handleCloseChat}
                          doctorName={displayName}
                          leaveRoom={this.props.leaveRoom}
                        />
                      )}
                    </>
                  )
                }
                return (
                  <>
                    <ChatContainer
                      {...this.props}
                      toggleVideo={this.toggleVideo}
                      sendRtt={sendRtt}
                      roomAddress={room.address}
                      displayName={displayName}
                    />
                    {this.state.lostPacient && (
                      <Disconnect
                        type='pacient'
                        roomId={roomId}
                        history={this.props.history}
                        pacient={this.props.pacient}
                        handleCloseChat={this.props.handleCloseChat}
                        doctorName={displayName}
                        leaveRoom={this.props.leaveRoom}
                      />
                    )}
                  </>
                )
              }}
            </Room>
          </Connected>
        </Provider>
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRoom)
