// Libs
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { ChatComposers, ChatList, Actions } from '@andyet/simplewebrtc'

// Components
import Messages from './Messages'

// Images
import camIcon from '../../assets/video-white.svg'
import send from '../../assets/send.svg'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onChat: (room, options) => dispatch(Actions.sendChat(room, options)),
  onChatState: (room, chatState) =>
    dispatch(Actions.sendChatState(room, chatState)),
})

// eslint-disable-next-line no-unused-vars
const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
// eslint-disable-next-line no-unused-vars
const configUrl = `https://api.simplewebrtc.com/config/guest/2e3ea92f92b90f5ec787d37f`

// Styles
const Content = styled.div`
  position: relative;
  width: calc(100% - 3rem);
  height: calc(100% - 2.5rem);
  margin: 0 auto;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 0 0 0.625rem #00000019;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4.5rem;
  padding: 0 1.5rem 0 2.5rem;
  background: #fff;
`

const Title = styled.p`
  margin: 0;
  font: 600 1rem 'Open Sans', sans-serif;
  color: #0561af;
`

const ChatHistory = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: calc(100% - 9.75rem);
  background: #f6f9fc;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background: transparent;
  }

  @media (max-width: 667px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Footer = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5.25rem;
  padding: 0 2.5rem;

  @media (max-width: 667px) {
    height: 4.375rem;
    bottom: -4.375rem;

    & > textarea {
      padding: 0.25rem;
    }
  }
`

const VideoCallbutton = styled.button`
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding: 0 1rem;
  border-radius: 0.1875rem;
  border: none;
  background-color: #0561af;
  outline: none;
  font: 400 0.75rem 'Open Sans', sans-serif;
  color: #fff;
  cursor: pointer;
`

const ButtonIcon = styled.img`
  width: 1rem;
  margin-left: 0.5rem;
`

const Input = styled.textarea`
  width: 100%;
  max-height: 100%;
  border: none;
  font: 400 1rem 'Open Sans', serif;
  color: #4d4d4d;
  resize: none;
  background: none;
  outline: none;

  ::-webkit-scrollbar {
    width: 0.375rem;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #e6eaea;
    margin: 10% 0;
  }

  ::-webkit-scrollbar-thumb {
    background: #cfd3d3;
    border-radius: 5px;
  }
`

const ChatButton = styled.button`
  background: transparent;
  cursor: pointer;
  outline: none;
  border: none;
`

const ChatSend = styled.img`
  display: block;
  width: 2rem;
  height: 2rem;
`

const ChatComposersBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 85%;
  height: 2.125rem;
  margin: 0.375rem;
  margin-left: 2.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: ${(props) =>
    props.me ? '6px 6px 0px 6px' : '6px 6px 6px 0px'};
  background: #edeff1;
  align-self: flex-start;
  box-shadow: 0px 1px 4px -1px #0000004c;
`

const isTyping = keyframes`
  0%,
  80%,
  100% {
    transform: translateY(2px);
    opacity: 1;
  }
  40% {
    transform: translateY(-2px);
    opacity: 0.5;
  }
`

const MessageTyping = styled.span`
  width: 0.625rem;
  height: 0.625rem;
  margin-left: 0.25rem;
  border-radius: 100%;
  display: inline-block;
  animation: ${isTyping} 0.85s infinite ease-in-out both;
  background: #707373;
  transform: translateY(2px);
`

class ChatContainer extends Component {
  constructor() {
    super()

    this.state = {
      text: '',
      rows: 1,
      minRows: 1,
      maxRows: 3,
    }
  }

  handleChange = (event) => {
    const textareaLineHeight = 24
    const { minRows, maxRows } = this.state

    const previousRows = event.target.rows
    event.target.rows = minRows // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight)

    if (currentRows === previousRows) {
      event.target.rows = currentRows
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows
      event.target.scrollTop = event.target.scrollHeight
    }

    this.setState({
      text: event.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    })

    if (!event.target.value.trim().length) {
      this.props.onChatState(this.props.roomAddress, 'active')
    } else {
      this.props.onChatState(this.props.roomAddress, 'composing')
    }
  }

  handleKeyEnter = (event) => {
    if (event.key === 'Enter') {
      this.handleSend(event)
    }
  }

  handleSend = (event) => {
    event.preventDefault()
    const { roomAddress, displayName } = this.props

    if (this.state.text) {
      this.props.onChat(roomAddress, {
        body: this.state.text.trim(),
        displayName,
      })

      this.setState({
        text: '',
        rows: 1,
      })
    }
  }

  render() {
    const { roomAddress, displayName, toggleVideo } = this.props
    const { rows, text } = this.state

    return (
      <>
        <Content>
          <Header>
            <Title>Chat de conversa</Title>
            <VideoCallbutton onClick={toggleVideo}>
              Chamada de vídeo
              <ButtonIcon src={camIcon} />
            </VideoCallbutton>
          </Header>
          <ChatHistory
            ref={(node) => {
              this.chatHistory = node
            }}
          >
            <ChatComposers
              room={roomAddress}
              render={({ composers }) => {
                if (composers.length) {
                  return (
                    <ChatComposersBox>
                      <MessageTyping style={{ animationDelay: '-0.32s' }} />
                      <MessageTyping style={{ animationDelay: '-0.16s' }} />
                      <MessageTyping />
                    </ChatComposersBox>
                  )
                }
                return null
              }}
            />
            <ChatList
              room={roomAddress}
              renderGroup={({ chats, peer }) => {
                return (
                  <Messages
                    key={chats[0].id}
                    chats={chats}
                    displayName={displayName}
                    scrollbar={this.chatHistory}
                  />
                )
              }}
            />
          </ChatHistory>
          <Footer onSubmit={this.handleSend}>
            <Input
              ref={(node) => {
                this.chatInput = node
              }}
              autoFocus
              rows={rows}
              value={text}
              placeholder='Escreva aqui a sua mensagem…'
              onChange={this.handleChange}
              onKeyUp={this.handleKeyEnter}
            />
            <ChatButton type='submit'>
              <ChatSend src={send} />
            </ChatButton>
          </Footer>
        </Content>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer)
